import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import LightBoxImage from '../Component/images/LightBoxImg.jpg';
import ZoomInIcon from '../Component/images/ZoomInIcon.png';

export default function ViewFloorplan() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className='container LightBoxMain pt-5 pb-5'>
        <div className='row LightBoxRow'>
          <div className='col-12 mt-3 mb-5'>
            <h2 className='text-start'>View Floor Plan</h2>
          </div>
          <div className='col-12 LightBoxCol shadow'>
            <img className='big-img' alt='lightbox-pic' src={LightBoxImage} />
            <div onClick={() => setIsOpen(true)} className='ImgOverlay'>
              <img src={ZoomInIcon} alt='zoom-pic'/>
            </div>
            {isOpen && (
              <Lightbox
                mainSrc={LightBoxImage}
                onCloseRequest={() => setIsOpen(false)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';
import { useState } from 'react';
import DatePicker from 'react-date-picker'
import moment from 'moment';
import axios from 'axios';
import toast from 'react-hot-toast';
import ClipLoader from "react-spinners/ClipLoader";

function RequestRentalModal(props) {
    const [name, setName] = useState('')
    const [nameErr, setNameErr] = useState(false)
    const [email, setEmail] = useState('')
    const [emailErr, setEmailErr] = useState(false)
    const [emailErrText, setEmailErrText] = useState('')
    const [number, setNumber] = useState('')
    const [numberErr, setNumberErr] = useState(false)
    const [sameCheck, setSameCheck] = useState('')
    const [whatsappNum, setWhatsappNum] = useState('')
    const [whatsappNumErr, setWhatsappNumErr] = useState(false)
    const [occupation, setOccupation] = useState('')
    const [occupationErr, setOccupationErr] = useState(false)
    const [income, setIncome] = useState('')
    const [incomeErr, setIncomeErr] = useState(false)
    const [moveDateErr, setMoveDateErr] = useState(false)
    const [numPeople, setNumPeople] = useState('')
    const [numPeopleErr, setNumPeopleErr] = useState(false)
    const [information, SetInformation] = useState('')
    const [informationErr, setInformationErr] = useState(false)
    let [loading, setLoading] = useState(false);

    const [dateValue, setDateValue] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    

    const handleName = (e) => {
        setName(e.target.value)
        if (e.target.value === '') {
            setNameErr(true)
        } else {
            setNameErr(false)
        }
    }

    const handleInformation = (e) => {
        SetInformation(e.target.value)
        if (e.target.value === '') {
            setInformationErr(true)
        } else {
            setInformationErr(false)
        }
    }

    console.log((new Date("2022-01-01")).getTime());
    

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    // function sbmtBtn(e){
    //     setLoading(true)
    // }
    const handleEmail = (e) => {
        if (!isValidEmail(e.target.value)) {
            setEmailErrText('Email is invalid');
            setEmailErr(true)
        } else {
            setEmailErrText(null);
            setEmailErr(false)
        }
        if (e.target.value === '') {
            setEmailErrText('Please Enter Email');

            setEmailErr(true)
        }
        setEmail(e.target.value)
    }

    const handleNum = (e) => {
        setNumber(e.target.value)
        if (sameCheck) {
            setWhatsappNum(e.target.value)

        }
        if (e.target.value === '') {
            setNumberErr(true)
        } else {
            setNumberErr(false)
        }
    }

    const handleWhatsappNum = (e) => {
        setWhatsappNum(e.target.value)
        if (e.target.value === '') {
            setWhatsappNumErr(true)
        } else {
            setWhatsappNumErr(false)
        }
    }

    const handleOccupation = (e) => {
        setOccupation(e.target.value)
        if (e.target.value === '') {
            setOccupationErr(true)
        } else {
            setOccupationErr(false)
        }
    }

    const handleIncome = (e) => {
        setIncome(e.target.value)
        if (e.target.value === '') {
            setIncomeErr(true)
        } else {
            setIncomeErr(false)
        }
    }


    const handleNumPeople = (e) => {
        setNumPeople(e.target.value)
        if (e.target.value === '') {
            setNumPeopleErr(true)
        } else {
            setNumPeopleErr(false)
        }
    }

    const handleCheck = (e) => {
        if (e.target.checked) {
            setWhatsappNum(number)
        }
        setSameCheck(e.target.checked)
    }

    const rentalSubmit = (e) => {
        e.preventDefault()
        
        var error = false
        if (name === '') {
            setNameErr(true)
            error = true
        } else {
            setNameErr(false)
        }
        if (information === '') {
            setInformationErr(true)
            error = true
        } else {
            setInformationErr(false)
        }
        if (email === '') {
            setEmailErr(true)
            setEmailErrText('Please Enter Email');
            error = true
        } else {
            setEmailErr(false)
        }
        if (number === '') {
            setNumberErr(true)
            error = true
        } else {
            setNumberErr(false)
        }
        if (whatsappNum === '') {
            setWhatsappNumErr(true)
            error = true
        } else {
            setWhatsappNumErr(false)
        }
        if (occupation === '') {
            setOccupationErr(true)
            error = true
        } else {
            setOccupationErr(false)
        }
        if (income === '') {
            setIncomeErr(true)
            error = true
        } else {
            setIncomeErr(false)
        }
        if (dateValue === '') {
            setMoveDateErr(true)
            error = true
        } else {
            setMoveDateErr(false)
        }
        if (numPeople === '') {
            setNumPeopleErr(true)
            error = true
        } else {
            setNumPeopleErr(false)
        }
        // if (nameErr || emailErr || numberErr || whatsappNumErr || occupationErr || incomeErr || moveDateErr || numPeopleErr) {
        if (error) {
            return
        }
        setLoading(true)

        setIsDisabled(true)
        var timestamp = Date.parse(dateValue)
        timestamp = timestamp/1000
        console.log(timestamp);
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/submit-offer-form`, {
            name: name,
            email: email,
            number: number,
            whatsapp_no: whatsappNum,
            occupation: occupation,
            annual_income: income,
            move_in_date: timestamp,
            no_of_people: numPeople,
            other_information:information
        })
            .then(function (response) {
                setLoading(false)
                if(response.data.status) {
                    setIsDisabled(false)
                    toast.success(response.data.msg)
                    props.setRentalModalShow(false)
                } else {
                    setIsDisabled(false)
                    toast.error(response.data.msg)

                }
              
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                toast.error(error.message)
                setLoading(false)
                setIsDisabled(false)

            })
    }

    function handleDate(e) {
        console.log(e);
        setDateValue(e)
        setMoveDateErr(false)
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='rental-modal'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Request Rental Offer Form
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={rentalSubmit}>
                    <Row>
                        <Col className='col-lg-6 col-xl-6 col-sm-6 col-12'>
                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={name}
                                    onChange={(e) => handleName(e)}
                                    disabled={isDisabled}
                                />
                                {nameErr ? <div className="form-error">This field is required</div> : null}
                            </Form.Group>
                        </Col>
                        <Col className='col-lg-6 col-xl-6 col-sm-6 col-12'>
                            <Form.Group className="mb-3">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    value={email}
                                    onChange={(e) => handleEmail(e)}
                                    disabled={isDisabled}
                                />
                                {emailErr ? <div className="form-error">{emailErrText}</div> : null}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='col-lg-6 col-xl-6 col-sm-6 col-12'>
                            <Form.Group className="">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={number}
                                    onChange={(e) => handleNum(e)}
                                    disabled={isDisabled}
                                />
                                {numberErr ? <div className="form-error">This field is required</div> : null}
                            </Form.Group>
                        </Col>
                        <Col className='col-lg-6 col-xl-6 col-sm-6 col-12'>

                            <Form.Group className="">
                                <Form.Label>Whatsapp Number</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={whatsappNum}
                                    onChange={(e) =>
                                        handleWhatsappNum(e)} disabled={sameCheck || isDisabled ? true : false} />
                                {whatsappNumErr ? <div className="form-error">This field is required</div> : null}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='col-12'>
                            <Form.Group className="mb-3 whatsapp-checkbox" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox"
                                    label="My WhatsApp number same as Mobile Number"
                                    value={sameCheck}
                                    onChange={(e) => handleCheck(e)}
                                    disabled={isDisabled}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='col-lg-6 col-xl-6 col-sm-6 col-12'>
                            <Form.Group className="mb-3 date-picker">
                                <Form.Label>Ideal move in date</Form.Label>
                                {/* this for error (add in input) */}
                                {/* value={moveDate} onChange={(e) => handleMoveDate(e)} */}
                                <DatePicker
                                    className='date-picker-input form-control'
                                    value={dateValue}
                                    onChange={(e) => handleDate(e)}
                                    minDate={moment().toDate()}
                                    disabled={isDisabled}
                                />
                                {moveDateErr ? <div className="form-error">This field is required</div> : null}
                            </Form.Group>
                        </Col>
                        <Col className='col-lg-6 col-xl-6 col-sm-6 col-12'>
                            <Form.Group className="mb-3">
                                <Form.Label>Number of people</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={numPeople}
                                    onChange={(e) => handleNumPeople(e)}
                                    disabled={isDisabled}
                                />
                                {numPeopleErr ? <div className="form-error">This field is required</div> : null}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='col-lg-6 col-xl-6 col-sm-6 col-12'>
                            <Form.Group className="mb-3">
                                <Form.Label>Occupation</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={occupation}
                                    onChange={(e) => handleOccupation(e)}
                                    disabled={isDisabled}
                                />
                                {occupationErr ? <div className="form-error">This field is required</div> : null}

                            </Form.Group>
                        </Col>
                        <Col className='col-lg-6 col-xl-6 col-sm-6 col-12'>
                            <Form.Group className="mb-3">
                                <Form.Label>Annual Income</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={income}
                                    onChange={(e) => handleIncome(e)}
                                    disabled={isDisabled}
                                    placeholder="1000"
                                />
                                {incomeErr ? <div className="form-error">This field is required</div> : null}
                            </Form.Group>
                        </Col>
                    </Row>

                    
                    <Row>
                        <Col className='col-lg-12 col-xl-12 col-sm-12 col-12'>
                            <Form.Group className="mb-3">
                                <Form.Label>Other Information</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Other Information"
                                    rows="5"
                                    value={information}
                                    
                                    onChange={(e) => handleInformation(e)}
                                    disabled={isDisabled}
                                />
                                {informationErr ? <div className="form-error">This field is required</div> : null}

                            </Form.Group>
                        </Col>
                    </Row>

                    <Col className='rental-button-div mt-2'>
                        {/* onClick={sbmtBtn} */}
                        <Button className={`rental-modal-btn ${loading ? 'disabled' : ''}`} type="submit">
                            {loading ? <ClipLoader size={30} className="me-2 rental-loader" color='white' /> : null}
                            Submit
                        </Button>
                    </Col>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
export default RequestRentalModal





import React, { useState, useEffect, useContext } from "react";
import { format, startOfWeek, startOfMonth, addDays, endOfMonth, endOfWeek, isSameDay, addMonths, subMonths } from "date-fns";
import "./Calendar.css";
import { BsChevronRight } from "react-icons/bs";
import { BsChevronLeft } from "react-icons/bs";
import axios from 'axios';
// import MobileCalendar from "./MobileCalendar";
import { useNavigate, useLocation } from "react-router-dom";
import { PropertyContext } from '../Context/PropertyContext';
import MobileAccordion from "./MobileAccordion";

const Calendar = ({ loggedInStatus }) => {
  const {propertyId, propertySlug, setTimeStampData} = useContext(PropertyContext)
  const [propId, setPropId] = useState('')
  // const [loadingValue, setLoading] = useState(false)
  const [availableDates, setAvailableDates] = useState([])
  const [responseData, setResponseData] = useState([])

  const navigate = useNavigate();
  const location = useLocation();

  // var timestamp = ''
  useEffect(() => {
    if(propertyId && propertyId !== '') {
      setPropId(propertyId)
    }
  }, [propertyId])
  
  useEffect(() => {

    if(propId !== '') {
    
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/available-datetime`, {
      property_id: propertyId
    })
      .then(function (response) {
        if(response.data) {
          setResponseData(response.data.data)
        }
        // handle success
        console.log(response);
        var timestamp = new Date(response.data.data[0].date * 1000);
        console.log(timestamp);
        var dateAvailabilityData =[]

        for(var i = 0; i < response.data.data.length; i++) {
          var a = new Date(response.data.data[i].date * 1000)
          var dd = String(a.getDate()).padStart(2, '0');
          var mm = String(a.getMonth() + 1).padStart(2, '0'); //January is 0!
          var yyyy = a.getFullYear();

          a = mm + '/' + dd + '/' + yyyy;

          dateAvailabilityData.push({date:a, availability: response.data.data[i].is_available})
        }
        setAvailableDates(dateAvailabilityData)
        console.log(dateAvailabilityData);
        
        // setLoading(true)   
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    }
  }, [propId]); // eslint-disable-line react-hooks/exhaustive-deps

  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  // const timeSlots = ['10:00', '12:00', '18:00']
  const [timeSlots, setTimeSlots] = useState([])
  const [showTimeSlot, setShowTimeSlot] = useState(false);
  const [showLeftIcon, setShowLeftIcon] = useState(false);
  const [showRightIcon, setShowRightIcon] = useState(true);
  const header = () => {
    const dateFormat = "MMMM yyyy";
    return (
      <div className="header row flex-middle">
        <div className="column col-start">
          <div className={showLeftIcon ? `icon` : `icon disabled-left-icon`} onClick={prevMonth}>
            <BsChevronLeft />
          </div>
        </div>
        <div className="column col-center">
          <span>{format(currentDate, dateFormat)}</span>
        </div>
        <div className="column col-end">
          <div className={showRightIcon ? `icon` : `icon disabled-left-icon`} onClick={nextMonth}>
            <BsChevronRight />
          </div>
        </div>
      </div>
    );
  };

  function handleShow(timeslot) {
    console.log(timeslot);
    localStorage.setItem('timestamp', timeslot.timestamp)
    localStorage.setItem('timestampTime', timeslot.time)
    localStorage.setItem('bookingType', 0)
    setTimeStampData(timeslot)
    if (loggedInStatus === true) {
      navigate(`/${propertySlug}/formbuilder`, {
        state: {
          from: "Person Booking",
          booking_type: 0,
          bookingId: location.state ? location.state.bookingId : ''
        }
      }

      )
    }
    else {
      navigate(`/${propertySlug}/Login`, {
        state: {
          from: "Person Booking",
          booking_type: 0,
          bookingId: location.state ? location.state.bookingId : ''
        }
      })
    }
  }

  const days = () => {
    const dateFormat = "ddd";
    const days = [];
    let startDate = startOfWeek(currentDate);
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="column col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return <div className="days row">
      <div className="column col-center">
        Sun
      </div>
      <div className="column col-center">
        Mon
      </div>
      <div className="column col-center">
        Tue
      </div>
      <div className="column col-center">
        Wed
      </div>
      <div className="column col-center">
        Thu
      </div>
      <div className="column col-center">
        Fri
      </div>
      <div className="column col-center">
        Sat
      </div>

    </div>;
  };
  const cells = () => {

    
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    console.log("selectedDate", selectedDate);

    var yesterdaydate = new Date();
    yesterdaydate.setDate(yesterdaydate.getDate() - 1);
    console.log("yesterdaydate", yesterdaydate);


    while (day <= endDate) {
      
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        var count = 0

        var dd = String(day.getDate()).padStart(2, '0');
        var mm = String(day.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = day.getFullYear();

        var onlyday = mm + '/' + dd + '/' + yyyy;
        for(var k=0; k< availableDates.length; k++) {
          if(availableDates[k].date === onlyday && availableDates[k].availability) {
            count ++
          }
        }
        
        days.push(
          <div
            className={`col text-center d-flex justify-content-center ${(day <= yesterdaydate || !count)
              ? "disabled" : isSameDay(day, selectedDate)
                ? "selected" : ""}`}
            key={day}

          >
            <div className="number"
              onClick={() => onDateClick(cloneDay)}>{formattedDate}</div>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}> {days} </div>
      );
      days = [];
    }
  console.log("count", count);

    return <div className="calendar-body">{rows}</div>;
  }
  const nextMonth = () => {

    var currDate = new Date()
    var currentMonth = currDate.getMonth()
    var abc = currentMonth + 1
    // var calendarMonth = currentDate.getMonth()
    if (currentDate.getMonth() === abc) {
      return
    }
    if (currentDate.getMonth() === currDate.getMonth()) {
      setShowRightIcon(false)

      setShowLeftIcon(true)

    }
    console.log(currentDate);
    setCurrentDate(addMonths(currentDate, 1));
  };
  const prevMonth = () => {
    console.log(currentDate);
    var currDate = new Date()
    if (currentDate.getMonth() === currDate.getMonth()) {
      return
    }
    setCurrentDate(subMonths(currentDate, 1));
    if (currentDate.getMonth() - 1 === currDate.getMonth()) {
      setShowRightIcon(true)

      setShowLeftIcon(false)
    }
  };
  const onDateClick = day => {
    console.log(day);
    setSelectedDate(day);
    setShowTimeSlot(true);
    var dd = String(day.getDate()).padStart(2, '0');
    var mm = String(day.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = day.getFullYear();

    var onlyday = dd + '-' + mm + '-' + yyyy;
    localStorage.setItem('bookingDate', onlyday)
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/available-timeslots`, {
      property_id: "uLZ11I",
      selectedDate: day
    })
      .then(function (response) {
        // handle success
        console.log(response.data.data.regulardata);
        const ts = []
        if (response.data.data.available_timeslots.length > 0) {
          response.data.data.available_timeslots.map((time) => {
            var s =  new Date(time * 1000).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            // s.replace(/(:\d{2}| [AP]M)$/, "");
            console.log(s);
            ts.push({time: s, timestamp: time})
            return 'value' 
          })
        }
        setTimeSlots(ts)
        console.log("ts", ts);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }
  
  return (
    <div className="calendar-container calender-container">
      <div className="row mt-5 mb-5 desktop-calender">
        <div className="col-12 mt-3 mb-5">
          <h2 className="text-start">In-Person Viewing</h2> 
        </div>
        <div className="col ps-0">
          <div className="calendar pt-3 SelectDate">
            <h4 className="ms-3 text-center">Select date and time</h4>
            <div>{header()}</div>
            <div>{days()}</div>
            <div>{cells()}</div>
          </div>
        </div>
        {showTimeSlot ? (
          <div className="col-12 col-lg-3 col-md-4 col-sm-12 time-col">
            <div className="timeslot-section d-flex flex-column SelectTime">
              <h4 className="mb-3 text-center">Select time</h4>

              
              {timeSlots.map((timeslot, index) =>
                <button key={index} onClick={() => handleShow(timeslot)} className="btn timeslot-btn mb-3">
                  {timeslot.time}
                </button>
              )}
            </div>
          </div>
        ) : null}
      </div>
      {/* <MobileCalendar responseData={responseData}/> */}
      <MobileAccordion responseData={responseData} loggedInStatus={loggedInStatus}/>
    </div>
  );
};
export default Calendar;
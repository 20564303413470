// import React from 'react'
// import Accordion from 'react-bootstrap/Accordion';
// import { useAccordionButton } from 'react-bootstrap/AccordionButton';
// import Card from 'react-bootstrap/Card';
// import { useState } from 'react';
// import { FaChevronDown } from "react-icons/fa";
// import { BsCalendar3 } from "react-icons/bs";


// const MobileCalendar = () => {

//     const dateData = [
//         {
//             day: "Monday ",
//             availibility: true,
//             date: "Jul 17, 2022",
//             timeslots: ["12:45 Pm", "1:30 Pm"]
//         },
//         {
//             day: "Tuesday",
//             availibility: true,
//             date: "Jul 18, 2022",
//             timeslots: ["12:45 Pm", "1:30 Pm"]
//         },
//         {
//             day: "Wednesday | Today",
//             availibility: true,
//             date: "Jul 19, 2022",
//             timeslots: ["12:45 Pm", "1:30 Pm"]
//         },
//         {
//             day: "Thursday",
//             availibility: true,
//             date: "Jul 20, 2022",
//             timeslots: ["12:45 Pm", "1:30 Pm"]
//         },
//         {
//             day: "Friday",
//             availibility: true,
//             date: "Jul 21, 2022",
//             timeslots: ["12:45 Pm", "1:30 Pm"]
//         },
//         {
//             day: "Saturday",
//             availibility: false,
//             date: "Jul 22, 2022",
//             timeslots: ["12:45 Pm", "1:30 Pm"]
//         },
//         {
//             day: "Sunday",
//             availibility: false,
//             date: "Jul 23, 2022",
//             timeslots: ["12:45 Pm", "1:30 Pm"]
//         }]
//     function CustomToggle({ children, eventKey, availibility }) {
//         const decoratedOnClick = useAccordionButton(eventKey, () =>
//             console.log('totally custom!', eventKey),
//         );

//         if (availibility === false) {
//             return (
//                 <>
//                     <div
//                         className="w-100 lastDays"
//                     >
//                         {children}

//                     </div>
//                 </>
//             );
//         } else {

//             return (
//                 <div
//                     className="w-100 calendar-current-day"
//                     onClick={decoratedOnClick}
//                 >
//                     {children}
//                 </div>
//             );
//         }
//     }
//     return (
//         <div className='accordion-main'>
//             <div className='BookAView'>
//                 <h2 className='text-start'>Book a viewing</h2>
//             </div>
//             <div className='accord-div shadow'>
//                 <h3>Choose one of the available days</h3>
//                 <Accordion defaultActiveKey="0" className='mobile-calender'>
//                     {dateData.map((dd, index) =>
//                     (<Card className='calendar-days'>
//                         <div>
//                             <Card.Header className='card-header'><div style={{
//                                 color: dd.availibility ? 'black' : 'lightgrey'
//                             }} className='date-fix'>{dd.date} <span className='mx-2'><FaChevronDown /></span></div>
//                                 <CustomToggle eventKey={index} availibility={dd.availibility}><BsCalendar3 />&nbsp; {dd.day}</CustomToggle>
//                             </Card.Header>
//                             {dd.availibility === true ? <Accordion.Collapse eventKey={index}>
//                                 <Card.Body className='mobile-calendar-time-slot'> {dd.timeslots.map((timeslot) => <span className='mx-2'>{timeslot}</span>)}</Card.Body>
//                             </Accordion.Collapse> : ''}
//                         </div>
//                     </Card>))
//                     }
//                 </Accordion>
//                 <div className='showMoreDaysDiv'>
//                     <a className='showMoreDays' href='#'>Show 7 days more</a>
//                 </div>
//             </div>
//             <div className='row bottom-row'>
//                 <div className='col-3'>

//                 </div>
//                 <div className='col-3'>

//                 </div>
//                 <div className='col-6 text-end p-0'>
//                     <button className='NextBtn'>Continue</button>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default MobileCalendar



import React, { useState, useEffect } from 'react'
import MobileCalendarAccordion from './MobileCalendarAccordion';
import './Accordion.css'


const MobileCalendar = ({ responseData, loggedInStatus }) => {
    const [index, setIndex] = useState(0)
    const [dateData, setDateData] = useState([])
    const [disableBtn, setDisableBtn] = useState(false)
    useEffect(() => {
        // console.log(responseData);
        setResponseDataFunc()
    }, [responseData]) // eslint-disable-line react-hooks/exhaustive-deps

    function setResponseDataFunc() {
        if (responseData.length > 0) {
            var dateArray = []
            for (var i = index; i < (index + 6); i++) {
                var a = new Date(responseData[i].date * 1000)
                var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

                var weekDay = days[a.getDay()]
                var dd = String(a.getDate()).padStart(2, '0');
                var mm = String(a.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = a.getFullYear();

                a = mm + '-' + dd + '-' + yyyy;
                dateArray.push({
                    date: a,
                    availibility: responseData[i].is_available,
                    weekDay: weekDay,
                    timestamp: responseData[i].date
                })
            }

            setDateData(dateArray)
        }
    }

    function showMoreFun() {
        setIndex(index + 6)
        if (index >= responseData.length) {
            setDisableBtn(true)
        } else {
            setDisableBtn(false)

        }
        setResponseDataFunc()
    }

    useEffect(() => {
    }, [index])

    return (
        <div className='accordion-main'>
            <div className="accord-div">

                <h3 className='pt-3'>Choose one of the available days</h3>
                <div><div className="accordion">
                    {dateData.map(({ date, availibility, weekDay, timestamp, index }) => (
                        <MobileCalendarAccordion key={weekDay} timestamp={timestamp} date={date} availibility={availibility} weekDay={weekDay} loggedInStatus={loggedInStatus} responseData={responseData} />
                    ))}
                </div>
                    <div className='text-center'>

                        <button disabled={disableBtn ? true : false} onClick={() => showMoreFun()} className='btn showMoreDays'>Show 7 days more</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileCalendar;
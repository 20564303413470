import React, { useEffect, useContext } from 'react';
import { Button, Form } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { useState } from 'react';
import OTPInput from "otp-input-react";
import { useNavigate } from "react-router-dom";
import axios from 'axios'; 
import toast, { Toaster } from 'react-hot-toast';
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { PropertyContext } from '../Context/PropertyContext';

export default function Login({loggedInStatus, setLoggedInStatus}) {
  const {propertySlug, setCustDetails} = useContext(PropertyContext)

  const location = useLocation();

  console.log(location);
  const [otpCode, setOtpCode] = useState("");
  const otphandleChange = (otpCode) => setOtpCode(otpCode);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [otpshow, setOtpShow] = useState(false);
	const [flag, setFlag] = useState(false);
	const [userId, setUserId] = useState('')
	const [otpSuccess, setOtpSuccess] = useState(false)
	const [customerName, setCustomerName] = useState('')
	const [customerNameErr, setCustomerNameErr] = useState(false)
  let [loading, setLoading] = useState(false);
  const [number, setNumber] = useState('')
  const [numberErr, setNumberErr] = useState(false)
  const [whatsappNumber, setWhatsappNumber] = useState('')
  const [whatsappNumberErr, setWhatsappNumberErr] = useState(false)
  const [sameCheck, setSameCheck] = useState('')
  const [counter, setCounter] = useState(1);
  const [hideResend, setHideResend] = useState(true)


	const navigate = useNavigate();

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = event => {

    if (!isValidEmail(event.target.value)) {
      setError('Email is invalid');
    } else {
      setError(null);
    }
    if (event.target.value === '') {
      setError(null);
    }
    setEmail(event.target.value);
  };

  function showin(e) {
    e.preventDefault()
    if(email === '') {
      setError('Please enter Email');
      return
    }
    if(error !== null) {
      return
    }
    setFlag(true);
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/send-otp`,{
      customer_email: email
    })
    .then(function (response) {
      // handle success
      console.log(response);
      setUserId(response.data.user_id)
      setOtpShow(true);
      setLoading(false)
      setCounter(59)
      setHideResend(true)

    })
    .catch(function (error) {
      // handle error
      console.log(error);
      toast.error(error.message)
      setLoading(false)

    })
  }
  function loginFunction(e) {
    e.preventDefault()
    // var a = Number(otpCode)
    setLoading(true)

    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/verify-otp`,{
      customer_email: email,
      otp_code:Number(otpCode),
      uid: userId
    })
    .then(function (response) {
      // handle success
      console.log(response);
      if(response.data.status === true) {
        if(response.data.is_verified) {
          if(location.state) {
            if(location.state.from === 'Person Booking') {
              navigate(`/${propertySlug}/formbuilder`, {
                state: {
                  from: "Person Booking"
                }
              })
            } else if(location.state.from === 'Video Viewing') {
              navigate(`/${propertySlug}/formbuilder`, {
                state: {
                  from: "Video Viewing"
                }
              })
            }
          }
           else {
            navigate("/" + propertySlug)
          }
          localStorage.setItem('custEmail', email)
          localStorage.setItem('custName', response.data.user_name)
          localStorage.setItem('custNumber', response.data.user_phone)

          setLoggedInStatus(true)

        } else {

          setOtpSuccess(true)
        }
        localStorage.setItem('token',response.data.token );

        setOtpShow(false)
        toast.success(response.data.msg)

      } else {
        toast.error(response.data.msg)
      }
      setLoading(false)
      
    })
    .catch(function (error) {
      // handle error
      console.log(error);
      setLoading(false)

    })
  }

function handleCustName(e) {
  setCustomerName(e.target.value)
  if(e.target.value === '') {
    setCustomerNameErr(true)
  } else {
    setCustomerNameErr(false)

  }
}

  function customerLogin(e) {
    e.preventDefault()
    if(customerName === '') {
      setCustomerNameErr(true)
    }
    if(number === '') {
      setNumberErr(true)
    }
    if(whatsappNumber === '') {
      setWhatsappNumberErr(true)
    }
    if(customerName === '' || number === '' || whatsappNumber === '') {
      return
    }

    setLoading(true)
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/customer-login`,{
      customer_name: customerName,
      mobile_no: number,
      whatsapp_no: whatsappNumber,
      uid: userId
    })
    .then(function (response) {
      // handle success
      console.log(response);
      if(response.data.status === true) {
        // localStorage.setItem('token',response.data.token );
        toast.success(response.data.msg)
        setLoggedInStatus(true)
        setCustDetails({
          custName: customerName,
          custEmail: email, 
          custNumber: number
        })
        localStorage.setItem('custName', customerName)
        localStorage.setItem('custEmail', email)
        localStorage.setItem('custNumber', number)
        if(location.state) {
          if(location.state.from === 'Person Booking') {
            navigate(`/${propertySlug}/formbuilder`, {
              state: {
                from: "Person Booking"
              }
            })
          } else if(location.state.from === 'Video Viewing') {
            navigate(`/${propertySlug}/formbuilder`, {
              state: {
                from: "Video Viewing"
              }
            })
          }
        }
         else {
          navigate("/" + propertySlug)
        }
      } else {
        toast.error(response.data.msg)

      }
      setLoading(false)

    })
    .catch(function (error) {
      // handle error
      console.log(error);
      setLoading(false)

    })
  }

  useEffect(() => {
    if(number === '' || !number) {
      setNumberErr(true)
    } else {
      setNumberErr(false)
      if(sameCheck) {
        setWhatsappNumber(number)
      }
    }
  },[number]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(whatsappNumber === '' || !whatsappNumber) {
      setWhatsappNumberErr(true)
    } else {
      setWhatsappNumberErr(false)

    }
  },[whatsappNumber])

  useEffect(() =>{
    setWhatsappNumberErr(false)
    setNumberErr(false)
},[])

useEffect(() => {
      if(counter === 0) {
          setHideResend(false)
      }
      const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
  // }
}, [counter]);

const handleCheck = (e) => {
  if(e.target.checked) {
      setWhatsappNumber(number)
  }
  setSameCheck(e.target.checked)
}
const resendCode = (e) =>{
  setHideResend(true)
  if(hideResend === false) {
      setCounter(59)

      resendOtp(e)
  }
}
const resendOtp = async (e) => {

  e.preventDefault()
  
  if (email === "" || email === undefined)
      return setError("Please enter a valid phone number!");
  
    if(email === '') {
      setError('Please enter Email');
      return
    }
    if(error !== null) {
      return
    }
    setFlag(true);
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/send-otp`,{
      customer_email: email
    })
    .then(function (response) {
      // handle success
      console.log(response);
      setUserId(response.data.user_id)
      setOtpShow(true);
      setLoading(false)
      setCounter(59)
      setHideResend(true)

    })
    .catch(function (error) {
      // handle error
      console.log(error);
      toast.error(error.message)
      setLoading(false)

    })
};

  return (
    <>
      <Toaster/>
      <section className="container my-5 p-5 login-container">
        
        <div className="login-form">
          
          {!otpSuccess ? (otpshow ? <Row className='OtpMainRow' style={{ display: flag ? "block" : "none" }}>
            <Col className='OtpMainCol col-sm-6 col-md-6 col-lg-6 col-xl-6 m-auto p-md-5 bg-white shadow rounded-3 mt-3'>
              <form>
              <h4 className='text-center mt-5'>Email Verification</h4>
              <h6 className='text-center'>by entering the 6 digit verification code received on your Email</h6>
              <div className="OtpInputDiv">
                <OTPInput
                  className="OtpInput"
                  type="otp"
                  otpType="number"
                  OTPLength={6}
                  required={true}
                  value={otpCode}
                  onChange={otphandleChange}       
                  disabled={loading ? true : false}
                  autoFocus
                />
                <Col>
                <div className='otp-status'>Didn’t receive OTP? {counter ? <span>Resend Code in  00:{counter}</span> : ''}     
                                {hideResend === true ? '' :
                                <span className='ps-1' onClick={(e) =>resendCode(e)}>RESEND CODE</span>
                                }
                                 </div>
                  {/* <button className='btn p-0' style={{color: 'rgb(105, 76, 178)', fontSize:'14px'}}>RESEND OTP in {counter}</button> */}
                </Col>
								</div>
								<div className="text-center continoue-btn text-lg-start pt-2">
									<button type="submit" onClick={(e) => loginFunction(e)} className={`btn ${loading ? 'disabled' : ''}`} >
                  {loading ? <ClipLoader size={30} className="me-2 " color='white'/> : null}
                    Continue</button>
                </div>
              </form>
            </Col>
          </Row> : <Row className="row login-row d-flex" style={{ display: !flag ? "block" : "none" }}>
            {/* <div className="col-md-12 col-lg-6 col-xl-6 p-0 login-form-image">
              <img src={login} className="img-fluid"
                alt="" />
            </div> */}
            <Col className="col-sm-12 col-md-8 col-lg-6 col-xl-6 m-auto bg-white shadow rounded-3 log-in-col px-0 email-col">
              <div style={{ display: loading ? "block" : "none" }}>

              </div>
              <form className='p-md-5'>
                {/* <h2 className='text-center my-4'>Oliver</h2> */}
                <h4 className='text-center mt-5'>Get Started</h4>
                <h6 className='text-center'>By entering your Email</h6>
                <div className="form-outline mb-4">
                  
                  <Form.Group className="mb-2" >
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" value={email}
                    onChange={handleChange}
                    disabled ={loading ? true : false}
                    id="input"/>
                  </Form.Group>
                {error && <div className='' style={{ color: 'red' }}>{error}</div>}
                </div>
                
                <div className="text-center continoue-btn text-lg-start mt-4">
                  <button onClick={showin} type="submit" className={`btn ${loading ? 'disabled' : ''}`}>
                    {loading ? <ClipLoader size={30} className="me-2 " color='white'/> : null}
                    
                    GET OTP</button>
                </div>

                <div className='my-4 details'><p>Click on "Continue" to receive a verification code. Message and data rates may apply.  By clicking continue, you agree to the Terms of Service and Privacy Police.</p></div>
              </form>
            </Col>
          </Row>) : 
          <Row className='' >
            <Col className='col-sm-6 col-md-6 col-lg-6 col-xl-6 m-auto p-3 p-md-5 bg-white shadow rounded-3 mt-3'>
            <h4 className='text-center mb-3'>Fill Your Details</h4>

              <Form className=''>
                
                <Form.Group className="mb-4" controlId="formCustName">

                    <Form.Label className='mb-2'>Your Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Your Name" value={customerName} onChange={handleCustName} disabled={loading ? true : false}/>
                  {customerNameErr ? <div className="form-error">This field is required</div> : null}
                </Form.Group>
                <Row>
                  <Col className='col-12 col-md-6 ps-0'>
                    <div className='mb-4'>
                      <Form.Label className='mb-2'>Mobile Number</Form.Label>
                      
                      <PhoneInput
                        international
                        placeholder="Enter Mobile Number"
                        value={number}
                        onChange={setNumber}
                        className="phone-number"
                        />
                        {numberErr ? <div className="form-error">This field is required</div> : null}
                      </div>
                      <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" className='num-check' label="My WhatsApp number same as Mobile Number" value={sameCheck} onChange={(e) => handleCheck(e)}/>
                    </Form.Group>
                  </Col>
                  <Col className='col-12 col-md-6 pe-0'>
                    <div className='mb-4'>
                    <Form.Label className='mb-2'>Whatsapp Number</Form.Label>
                    
                    <PhoneInput
                      international
                      placeholder="Enter Whatsapp Number"
                      value={whatsappNumber}
                      onChange={setWhatsappNumber}
                      className="phone-number"
                      disabled={sameCheck ? true : false}
                      />
                      {whatsappNumberErr ? <div className="form-error">This field is required</div> : null}
                  </div>
                  </Col>
                </Row>
                

                

                <Button type='submit' className={`submit-btn ${loading ? 'disabled' : ''}`} onClick={ customerLogin}>
                  {loading ? <ClipLoader size={30} className="me-2 " color='white'/> : null}
                  
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
          }
          
        </div>
      </section>
    </>
  );
}


import React from 'react';
import { Container } from 'react-bootstrap';
// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useContext } from 'react';
import axios from 'axios';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ClipLoader from "react-spinners/ClipLoader";
import DatePicker from 'react-date-picker';
import { useNavigate, useLocation } from "react-router-dom";
import { PropertyContext } from '../Context/PropertyContext';



export default function FormBuilder() {
    const navigate = useNavigate();
    const { propertySlug } = useContext(PropertyContext)
    const location = useLocation();

    const [logInStatus, setLogInStatus] = useState([])
    const [formData, setFormData] = useState([])
    const [text, setText] = useState([])
    const [textErr, setTextErr] = useState([])
    const [date, setDate] = useState([])
    const [dateErr, setDateErr] = useState([])
    const [number, setNumber] = useState([])
    const [numberErr, setNumberErr] = useState([])
    const [textarea, setTextarea] = useState([])
    const [textareaErr, setTextareaErr] = useState([])
    // const [checkbox, setCheckbox] = useState('')
    // const [checkboxErr, setCheckboxErr] = useState(false)
    const [currentRadioValue, setCurrentRadioValue] = useState([])
    const [radioValueErr, setRadioValueErr] = useState([])
    const [dropdownState, setDropdownState] = useState([])
    const [dropdownStateErr, setDropdownStateErr] = useState([])
    // const [anyselect, setAnySelect] = useState('')
    const [flag, setFlag] = useState(false)
    let [loading, setLoading] = useState(false);
    const [checkedState, setCheckedState] = useState([])
    const [checkedStateErr, setCheckedStateErr] = useState([])
    const [ifCheckonPage, setOfCheckonPage] = useState(false)

    const handleRadioChange = (e, index) => {

        let obj = currentRadioValue
        obj[index] = e.target.value
        setCurrentRadioValue(obj)
        var abc = currentRadioValue[index] === e.target.value
        console.log(abc);
        var objErr = radioValueErr
        if (obj[index] === '') {
            objErr[index] = true
        } else {
            objErr[index] = false
        }
        setRadioValueErr(objErr)
        setFlag(!flag)
    }

    const handleText = (e, index) => {
        var arr = text
        arr[index] = e.target.value
        // var arr = {
        //     [index]: e.target.value,

        // }
        setText(arr)
        var objErr = textErr
        if (arr[index] === '') {
            objErr[index] = true
        } else {
            objErr[index] = false
        }
        setTextErr(objErr)
        setFlag(!flag)
    }

    const handleDate = (e, index) => {
        var arr = date
        arr[index] = e
        setDate(arr)
        var dtErr = dateErr
        if (arr[index] === '') {
            dtErr[index] = true
        } else {
            dtErr[index] = false
        }
        setDateErr(dtErr)
        setFlag(!flag)
    }

    const handleNumber = (e, index) => {
        var arr = number
        arr[index] = e.target.value
        setNumber(arr)
        var nrErr = numberErr
        if (arr[index] === '') {
            nrErr[index] = true
        } else {
            nrErr[index] = false
        }
        setNumberErr(nrErr)
        setFlag(!flag)
    }

    // const handleDate = (e) => {
    //     setDate(e.target.value)
    //     if (e.target.value === '') {
    //         setDateErr(true)
    //     } else {
    //         setDateErr(false)
    //     }
    // }

    // const handleNumber = (e) => {
    //     setNumber(e.target.value)
    //     if (e.target.value === '') {
    //         setNumberErr(true)
    //     } else {
    //         setNumberErr(false)
    //     }
    // }

    const handleTextarea = (e, index) => {
        var arr = textarea
        arr[index] = e.target.value
        setTextarea(arr)
        var taErr = textareaErr
        if (arr[index] === '') {
            taErr[index] = true
        } else {
            taErr[index] = false
        }
        setTextareaErr(taErr)
        setFlag(!flag)
    }
    // const handleTextarea = (e) => {
    //     setTextarea(e.target.value)
    //     if (e.target.value === '') {
    //         setTextareaErr(true)
    //     } else {
    //         setTextareaErr(false)
    //     }
    // }

    const handleCheckbox = (e, index, key, isRequired) => {
        // console.log(e.target.checked);
        // console.log(e.target.value);
        // if (checkedState?.length === 0) {
        //     checkedStateArr = new Array(chkInputLength)
        // } else {
        let checkedStateArr = checkedState
        // }
        // console.log(checkedStateArr);
        // // var innerArr = []
        // if (e.target.checked) {
        checkedStateArr.map((chkArr) => {
             
            if (chkArr.index === index) {
                chkArr[key] = e.target.checked

            }
            return 'value' 
        })
        setCheckedState(checkedStateArr)
        let checkflag = 0
        if (isRequired) {
            console.log(checkflag);
            for (let i = 0; i < checkedStateArr.length; i++) {
                if (checkedStateArr[i].index === index) {
                    for (const property in checkedStateArr[i]) {
                        if (checkedStateArr[i][property] === true) {
                            checkflag = 1
                        }
                    }
                }
            }
            let checkErrArr = checkedStateErr
            if (checkflag) {
                checkErrArr[index] = false

            } else {
                checkErrArr[index] = true

            }
            setCheckedStateErr(checkErrArr)
            setFlag(!flag)
        }
        // checkedStateArr[index]
        // } else {

        // }
    }

    // const handleRadio = (e) => {
    //     setRadio(e.target.value)
    //     if (e.target.value === '') {
    //         setRadioErr(true)
    //     } else {
    //         setRadioErr(false)
    //     }
    // }

    // const handleSelect = (e) => {
    //     setSelect(e.target.value)
    //     if (e.target.value === anyselect) {
    //         setSelectErr(true)
    //     } else {
    //         setSelectErr(false)
    //     }
    // }

    const builderSubmit = (e) => {
        e.preventDefault()

        console.log(text);
        console.log(date);
        console.log(number);
        console.log(checkedState);
        console.log(textarea);
        console.log(dropdownState)
        console.log(currentRadioValue)

        var error = false
        let objErr = textErr
        let taErr = textareaErr
        let dtErr = dateErr
        let nrErr = numberErr
        let chkArr = checkedState
        let checkErrArr = []
        let selectArr = []
        let radioErr = []
        formData.map((td, index) => {

            if (td.is_required && td.type === 'text') {
                
                if (text[index]) {
                    if (text[index] === '') {
                        objErr[index] = true
                        error = true
                    } else {
                        objErr[index] = false
                    }
                } else {
                    objErr[index] = true
                    error = true
                }
            }

            if (td.is_required && td.type === 'textarea') {
                if (textarea[index]) {
                    if (textarea[index] === '') {
                        taErr[index] = true
                        error = true
                    } else {
                        taErr[index] = false
                    }
                } else {
                    taErr[index] = true
                    error = true
                }
            }

            if (td.is_required && td.type === 'date') {
                if (date[index]) {
                    if (date[index] === '') {
                        dtErr[index] = true
                        error = true
                    } else {
                        dtErr[index] = false
                    }
                } else {
                    dtErr[index] = true
                    error = true
                }
            }

            if (td.is_required && td.type === 'number') {
                if (number[index]) {
                    if (number[index] === '') {
                        nrErr[index] = true
                        error = true
                    } else {
                        nrErr[index] = false
                    }
                } else {
                    nrErr[index] = true
                    error = true
                }
            }

            if (td.is_required && td.type === 'single_choice') {
                if (currentRadioValue[index]) {
                    if (currentRadioValue[index] === '') {
                        radioErr[index] = true
                        error = true
                    } else {
                        radioErr[index] = false
                    }
                } else {
                    radioErr[index] = true
                    error = true
                }
            }

            if (td.is_required && td.type === 'multiple_choice') {
                let checkflag = 0
                for (let i = 0; i < chkArr.length; i++) {
                    if (chkArr[i].index === index) {
                        for (const property in chkArr[i]) {
                            if (chkArr[i][property] === true) {
                                checkflag = 1
                            }
                        }
                    }
                }
                if (checkflag) {
                    checkErrArr[index] = false

                } else {
                    checkErrArr[index] = true
                    error = true

                }

            }
            if (td.is_required && td.type === 'list') {
                console.log(dropdownState);
                if (dropdownState[index] === '') {
                    selectArr[index] = true
                    error = true
                }
            }
            return 'value' 
        })
        setTextErr(objErr)
        setFlag(!flag)
        setDateErr(dtErr)
        setFlag(!flag)
        setNumberErr(nrErr)
        setFlag(!flag)
        setTextareaErr(taErr)
        setCheckedStateErr(checkErrArr)
        setDropdownStateErr(selectArr)
        setRadioValueErr(radioErr)
        setFlag(!flag)

        // if (objErr || taErr || dtErr || nrErr || radioErr || radioErr || checkErrArr) {
        //     return
        // }

        // if (setTextErr || setDateErr || setNumberErr || setTextareaErr || setCheckedStateErr || setDropdownStateErr || setRadioValueErr) {
        //     return
        // }
        if (error) {
            return
        }
        // console.log(text, date, number, textarea, currentRadioValue, dropdownState, checkedState);
        builderSubmitApi(text, date, number, textarea, currentRadioValue, dropdownState, checkedState)


        setLoading(true)

    }

    function builderSubmitApi(text, date, number, textarea, currentRadioValue, dropdownState, checkedState) {
        console.log(formData);
        const submitFormData = {}
        if (text.length > 0) {
            text.map((item, key) => {
                let guid = formData[key].guid
                submitFormData[guid] = item

                return 'value' 
            })
        }
        if (date.length > 0) {
            date.map((item, key) => {
                let guid = formData[key].guid
                submitFormData[guid] = item
                return 'value' 

            })
        }
        if (number.length > 0) {
            number.map((item, key) => {
                let guid = formData[key].guid
                submitFormData[guid] = item
                return 'value' 

            })
        }
        if (textarea.length > 0) {
            textarea.map((item, key) => {
                let guid = formData[key].guid
                submitFormData[guid] = item
                return 'value' 

            })
        }
        if (currentRadioValue.length > 0) {
            currentRadioValue.map((item, key) => {
                let guid = formData[key].guid
                submitFormData[guid] = item

                return 'value' 
            })
        }
        if (dropdownState.length > 0) {
            dropdownState.map((item, key) => {
                let guid = formData[key].guid
                submitFormData[guid] = item

                return 'value' 
            })
        }
        if (ifCheckonPage) {
            let guid
            for (let i = 0; i < checkedState.length; i++) {
                guid = formData[checkedState[i].index].guid

                submitFormData[guid] = checkedState[i]

            }

        }
        console.log(submitFormData);

        if (logInStatus === true) {
            if (localStorage.bookingType === 0) {

                navigate(`/${propertySlug}/Bookingspage`, {
                    state: {
                        from: "Person Booking",
                        booking_type: 0,
                        bookingId: location.state ? location.state.bookingId : '',
                        submitFormData
                    }
                }

                )
            } else {
                navigate(`/${propertySlug}/Bookingspage`, {
                    state: {
                        from: "Video Viewing",
                        booking_type: 1,
                        bookingId: location.state ? location.state.bookingId : '',
                        submitFormData
                    }
                }

                )

            }
        }
    }

    useEffect(() => {
        if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
            setLogInStatus(true)
        }
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/booking-builder-form`, {

        })
            .then(function (response) {
                var allfields = response.data.data.data.fields
                setLoading(false)
                console.log(allfields)


                setFormData(allfields)
                console.log(formData)
                let checkboxArr = []
                let dropdownArr = []
                for (let i = 0; i < allfields.length; i++) {
                    if (allfields[i].type === 'multiple_choice') {
                        setOfCheckonPage(true)
                        let subArr = {}
                        for (let j = 0; j < allfields[i].values.length; j++) {
                            subArr.index = i
                            subArr[allfields[i].values[j]] = (false)
                        }
                        checkboxArr.push(subArr)
                    }
                    if (allfields[i].type === 'list') {
                        dropdownArr[i] = ''
                    }
                }
                // console.log(theData)
                console.log(checkboxArr);
                setCheckedState(checkboxArr)
                setDropdownState(dropdownArr)
            })
            .catch(function (error) {
                setLoading(false)

            })

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleDropdown = (e, index, isRequired) => {
        let dropdownarr = dropdownState
        dropdownarr[index] = e
        setDropdownState(dropdownarr)
        console.log(e);
        let selectErrArr = dropdownStateErr
        if (isRequired) {
            if (e === 'Open this select menu') {
                selectErrArr[index] = true

            } else {
                selectErrArr[index] = false
            }
            setDropdownStateErr(selectErrArr)
        }
        setFlag(!flag)

    }


    return (
        <>
            <Container className='pt-5 pb-5'>
                <Row className='justify-content-center'>
                    <Col className='col-6'>
                        <Form onSubmit={builderSubmit}>
                            {formData.map((formItem, index) =>
                                <>
                                    {formItem.type === 'text' ?
                                        <Form.Group key={index} className="mb-4 builder-input-parent">
                                            <Form.Label>{formItem.name}<span className='text-danger'>{formItem.is_required === 1 ? " *Required" : ""}</span></Form.Label>
                                            <Form.Control
                                                key={formItem.guid}
                                                id={formItem.guid}
                                                type={formItem.type}
                                                onChange={(e) => handleText(e, index, formItem.is_required)}
                                                value={text[index] || ''}
                                            />
                                            {textErr[index] ? <div className="form-error">This field is required</div> : null}
                                        </Form.Group>
                                        : ''}
                                    {formItem.type === 'date' ?
                                        <Form.Group key={index} className="mb-4 builder-input-parent">
                                            <Form.Label>{formItem.name}<span className='text-danger'>{formItem.is_required === 1 ? " *Required" : ""}</span></Form.Label>
                                            <DatePicker
                                                className='form-control formbuilder-datepicker'
                                                key={formItem.guid}
                                                id={formItem.guid}
                                                type={"date"}
                                                value={date[index] || ''}
                                                onChange={(e) => handleDate(e, index, formItem.is_required)}
                                            />
                                            {dateErr[index] ? <div className="form-error">This field is required</div> : null}
                                        </Form.Group>
                                        : ''}
                                    {formItem.type === 'number' ?
                                        <Form.Group key={index} className="mb-4 builder-input-parent">
                                            <Form.Label>{formItem.name}<span className='text-danger'>{formItem.is_required === 1 ? " *Required" : ""}</span></Form.Label>
                                            <Form.Control
                                                key={formItem.guid}
                                                id={formItem.guid}
                                                value={number[index] || ''}
                                                type={"number"}
                                                onChange={(e) => handleNumber(e, index, formItem.is_required)}
                                            />
                                            {numberErr[index] ? <div className="form-error">This field is required</div> : null}
                                        </Form.Group>
                                        : ''}
                                    {formItem.type === 'single_choice' ?
                                        <Form.Group key={index} className=' mb-4 builder-input-parent' onChange={(e) => handleRadioChange(e, index, formItem.is_required)}>
                                            <Form.Label htmlFor={formItem.guid}>{formItem.name}<span className='text-danger'>{formItem.is_required === 1 ? " *Required" : ""}</span></Form.Label>
                                            {formItem.values && formItem.values.map((value, index) => (
                                                <Form.Check
                                                    value={value}
                                                    name={value}
                                                    label={value}
                                                    // key={index}
                                                    type="radio"
                                                    id={formItem.guid}
                                                    checked={currentRadioValue[index] === value}
                                                    readOnly={true}
                                                />
                                            ))}
                                            {radioValueErr[index] === true ? <div className="form-error">This field is required</div> : null}
                                        </Form.Group>
                                        : ''
                                    }
                                    {formItem.type === 'multiple_choice' ?
                                        <Form.Group key={index} className='mb-4 builder-input-parent'>
                                            <Form.Label>{formItem.name}<span className='text-danger'>{formItem.is_required === 1 ? " *Required" : ""}</span></Form.Label>
                                            {formItem.values && formItem.values.map((inputItem, index) => (
                                                <>
                                                    <Form.Check
                                                        //  key={index}
                                                        value={inputItem}
                                                        label={inputItem}
                                                        name={formItem.name}
                                                        type="checkbox"
                                                        id={formItem.guid}
                                                        onChange={(e) => handleCheckbox(e, index, inputItem, formItem.is_required)}
                                                    // checked={(checkedState.some(data => data == type)) ? true : false}
                                                    />
                                                </>
                                            ))}
                                            {checkedStateErr[index] === true ? <div className="form-error">This field is required</div> : null}
                                        </Form.Group>
                                        : ''
                                    }
                                    {formItem.type === 'list' ?
                                        <div className='mb-4 builder-input-parent'>
                                            <Form.Label>{formItem.name}<span className='text-danger'>{formItem.is_required === 1 ? " *Required" : ""}</span></Form.Label>
                                            <Form.Select key={index} aria-label="Default select example" onChange={(e) => handleDropdown(e.target.value, index, formItem.is_required)}>
                                                <option>Open this select menu</option>
                                                {formItem.values && formItem.values.map((type, index) => (
                                                    <option
                                                        id={formItem.guid}
                                                    // value={}
                                                    >{type}</option>
                                                ))}
                                            </Form.Select>
                                            {dropdownStateErr[index] ? <div className="form-error">This field is required</div> : null}
                                        </div>
                                        : ''
                                    }
                                    {formItem.type === 'textarea' ?
                                        <Form.Group key={index} className="mb-4 builder-input-parent">
                                            <Form.Label>{formItem.name}<span className='text-danger'>{formItem.is_required === 1 ? " *Required" : ""}</span></Form.Label>
                                            <Form.Control
                                                as={"textarea"}
                                                key={formItem.guid}
                                                id={formItem.guid}
                                                type={formItem.type}
                                                value={textarea[index] || ''}
                                                onChange={(e) => handleTextarea(e, index, formItem.is_required)}
                                            />
                                            {textareaErr[index] ? <div className="form-error">This field is required</div> : null}
                                        </Form.Group>
                                        : ''}
                                </>
                            )}
                            <Form.Group className='form-builder-btn'>
                                <Button className={`${loading ? 'disabled' : ""}`} type="submit">
                                    {loading ? <ClipLoader size={30} className="me-2 rental-loader" color='white' /> : null}
                                    Submit
                                </Button>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

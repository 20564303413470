
import React, {useContext, useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { FaCalendarAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { PropertyContext } from '../Context/PropertyContext';
import axios from 'axios';
import toast from 'react-hot-toast';

export default function Home(props) {
  const {propertyDetails, fetchPropertyDetails} = useContext(PropertyContext)
  const [inPersonViewing, setinPersonViewing] = useState('')
  const [virtualViewing, setinVirtualViewing] = useState('')
  const [instantVirtualViewing, setInstantVirtualViewing] = useState('')
  const [viewFloorPlan, setViewFloorPlan] = useState('')
    useEffect(() => {
        fetchPreferenceDetails()
        
        if(fetchPropertyDetails){
            fetchPropertyDetails()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const fetchPreferenceDetails = async () => {
        try {
          const result = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/get-preferences-details`)
          console.log(result);
          let preferenceData = result.data.result
          if(preferenceData) {

              if(preferenceData.inperson_viewing && preferenceData.inperson_viewing !== '') {
                setinPersonViewing(preferenceData.inperson_viewing)
              }
              if(preferenceData.virtual_viewing && preferenceData.virtual_viewing !== '') {
                setinVirtualViewing(preferenceData.virtual_viewing)
              }
              if(preferenceData.navigate_tour && preferenceData.navigate_tour !== '') {
                setInstantVirtualViewing(preferenceData.navigate_tour)
              }
              if(preferenceData.view_floor_plan && preferenceData.view_floor_plan !== '') {
                setViewFloorPlan(preferenceData.view_floor_plan)
              }
          }
            // if(result)
        }
        catch (error) {
          console.log(error);
          toast.error(error.message)
        }
      }

       function selectProperty(slug) {
        window.location.href = '/' + slug;
      }

    return (
        <>
           
            <Container className='py-5 middle-section'>
                <Row>
                    <Col lg={12} md={12} sm={12} className='details'>
                        <p className='mx-lg-5'>{propertyDetails.about_property}</p>
                    </Col>
                </Row>
                <Row className='py-3'>
                    <Col lg={12} md={12} sm={12}>
                        <Row className='justify-content-center Book'>
                            {propertyDetails.book_an_in_person_viewing ? 
                            <Link to="bookings" className='col-lg-5 col-md-12 mx-4'>
                                <div className='mb-lg-5 p-4 my-2 bg-light linking'>
                                    <Row>
                                        <div className='col-10'>
                                            <h3>In-Person Viewing</h3>
                                            <p>{inPersonViewing ? inPersonViewing : ''}</p>
                                        </div>
                                        <div className=' text-end my-4 col-2'> <h2><FaCalendarAlt /></h2></div>
                                    </Row>
                                </div>
                            </Link>
                            :''}
                            {propertyDetails.book_live_video_viewing ?
                            <Link to="videobookings" className='col-lg-5 col-md-12'>
                                <div className='mb-lg-5 my-2 p-4 bg-light linking'>
                                    <Row>
                                        <div className='col-10'>
                                            <h3>Virtual Viewing</h3>
                                            <p>{virtualViewing ? virtualViewing : ''}</p>
                                        </div>
                                        <div className=' text-end my-4 col-2'> <h2><FaCalendarAlt /></h2></div>
                                    </Row>
                                </div>
                            </Link>
                            : ''}
                        </Row>
                        <Row className='justify-content-center Book'>
                            {propertyDetails.watch_recorded_video_section ? 
                            <Link to="recordedvideos" className='col-lg-5 col-md-12 mx-4'>
                                <div className='mb-lg-5 my-2 p-4 bg-light linking'>
                                    <Row>
                                        <div className='col-10'>
                                            <h3>Instant Virtual Viewing</h3>
                                            <p>{instantVirtualViewing ? instantVirtualViewing : ''}</p>
                                        </div>
                                        <div className=' text-end my-4 col-2'> <h2><FaCalendarAlt /></h2></div>
                                    </Row>
                                </div>
                            </Link>
                            : ''}
                            {propertyDetails.floor_plan_section ? 
                            <Link to="viewfloorplan" className='col-lg-5 col-md-12'>
                                <div className='mb-lg-5 my-2 p-4 bg-light linking'>
                                    <Row>
                                        <div className='col-10'>
                                            <h3>View Floor Plan</h3>
                                            <p>{viewFloorPlan ? viewFloorPlan : ''}</p>
                                        </div>
                                        <div className=' text-end my-4 col-2'> <h2><FaCalendarAlt /></h2></div>
                                    </Row>
                                </div>
                            </Link>
                            : ''}
                            {/* {propertyDetails.status === 0 && propertyDetails.additional_property === 1 &&  propertyDetails.related_property.length  > 0 ? propertyDetails.related_property.map(related_property_item => {
                                return (<Link to={"/" + related_property_item.property_slug} className='col-lg-5 col-md-12'> 
                                <Card className='property-link-card'>
                                    <img src={process.env.REACT_APP_API_ENDPOINT + '/property/uploads/'+related_property_item.property_logo}/>
                                <span>{related_property_item.property_name}</span>
                                </Card>
                                </Link>)
                            }) 
                            

                            : <h2 className='no-property-text'>No property found</h2>} */}
                            {propertyDetails.status === 0 ? (propertyDetails.additional_property === '1' &&  propertyDetails.related_property.length  > 0 ? propertyDetails.related_property.map(related_property_item => {
                                return (<div key={related_property_item} onClick={() => selectProperty(related_property_item.property_slug)} className='col-lg-5 col-md-12 curosor-pointer'> 
                                <Card className='property-link-card'>
                                    <img alt='card-img' src={process.env.REACT_APP_API_ENDPOINT + '/property/uploads/'+related_property_item.property_logo}/>
                                <span>{related_property_item.property_name}</span>
                                </Card>
                                </div>)
                            }) 
                            

                            : <h2 className='no-property-text'>No property found</h2>): ''}
                        </Row>
                        {/* <Row className='justify-content-center Book'>
                            <Link to="nagivatetour" className='col-lg-5 col-md-12'>
                                <div className='mb-lg-5 my-2 p-4 bg-light linking'>
                                    <Row>
                                        <div className='col-10'>
                                            <h3>Navigate 3D Tour</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetuer
                                                adipiscing elit. Aenean commodo ligula eget</p>
                                        </div>
                                        <div className=' text-end my-4 col-2'> <h2><FaCalendarAlt /></h2></div>
                                    </Row>
                                </div>
                            </Link>

                            <Col lg={5} className='p-4'></Col>

                        </Row> */}
                    </Col>

                </Row>
            </Container>


        </>
    )
}

import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { PropertyContext } from '../Context/PropertyContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import moment from 'moment';
import ClipLoader from "react-spinners/ClipLoader";

export default function BookingsPage(props) {
  const { propertyDetails, propertySlug, propertyId } = useContext(PropertyContext)

  const navigate = useNavigate();
  const location = useLocation();

  const [bookingDate, setBookingDate] = useState('')
  const [bookingTime, setBookingTime] = useState('')
  const [boookingConfirmLoading, setBookingConfirmLoading] = useState(false)
  const [bookingId, setBookingId] = useState('')

  function getlength(number) {
    return number.toString().length;
  }
  useEffect(() => {
    var getTimeFromDate = new Date(localStorage.timestamp * 1000);
    // console.log(moment(getTimeFromDate).format('h:mm A'));
    // var getHoursFromDate = getTimeFromDate.getHours()
    var getMinutesFromDate = getTimeFromDate.getMinutes()
    // var getdayFromDate = getTimeFromDate.getDate()
    // let ampm = getHoursFromDate >= 12 ? '' : 'am'
    var ampm = moment(getTimeFromDate).format('h:mm A');

    // console.log(getdayFromDate, "getdayFromDate");
    // console.log(getMinutesFromDate);
    let minutesLength = getlength(getMinutesFromDate)
    if (minutesLength === 1) {
      getMinutesFromDate = '0' + getMinutesFromDate
    }
    var dd = String(getTimeFromDate.getDate()).padStart(2, '0');
    var mm = String(getTimeFromDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = getTimeFromDate.getFullYear();
    // console.log(dd, 'dd');
    // console.log(mm, 'mm');
    // console.log(yyyy, 'yyyy');
    if (location.state) {
      if (location.state.booking_type === 1) {
        setBookingDate(`${dd}-${mm}-${yyyy}`)
        setBookingTime(` ${ampm}`)
      } else if (location.state.booking_type === 0) {
        setBookingDate(localStorage.getItem('bookingDate'))
        setBookingTime(localStorage.getItem('timestampTime'))
      }
      if(location.state.bookingId !== '') {
        setBookingId(location.state.bookingId)
      }
    }

  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  const confirmBooking = async () => {
    var token = localStorage.getItem('token')
    setBookingConfirmLoading(true)
    try {
      const result = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/bookings`, {
        booking_type: location.state ? location.state.booking_type : '',
        booking_time: localStorage.getItem('timestamp'),
        // booking_date_end_time: localStorage.getItem('timestamp'),
        token: token,
        header: 'Content-Type: application/x-www-form-urlencoded',
        property_id: propertyId,
        customFormData: location.state.submitFormData
      })
      if (result.data.status) {
        navigate(`/${propertySlug}/afterconfirm`)
      }
      setBookingConfirmLoading(false)
    }
    catch (error) {
      console.log(error);
      toast.error(error.message)
      setBookingConfirmLoading(false)

    }
  }

  const rescheduleBooking = async () => {
    var token = localStorage.getItem('token')
    setBookingConfirmLoading(true)
    try {
      const result = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/reschedule-booking`, {
        booking_id: bookingId,
        booking_type: location.state ? location.state.booking_type : '',

        booking_time: localStorage.getItem('timestamp'),
        // booking_date_end_time: localStorage.getItem('timestamp'),
        token: token,
        header: 'Content-Type: application/x-www-form-urlencoded',
        property_id: propertyId
      })
      if (result.data.status) {
        navigate(`/${propertySlug}/afterconfirm`)
      }
      setBookingConfirmLoading(false)
    }
    catch (error) {
      console.log(error);
      toast.error(error.message)
      setBookingConfirmLoading(false)

    }
  }

  return (
    <>

      {props.loggedInStatus ? <section className='container py-5 booking-main my-4'>
        <div className='row'>
          <h2 className='my-4 text-start'>Confirm Your Booking</h2>
          <div className='col-lg-12 mt-3'>
            <div className='row'>
              <div className='col-lg-12'>
                <h4 className='mb-3 '>Property Details</h4>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-4 col-sm-12 col-12 col-6 col-md-6'>
                <div className='property-name'>
                  <span>Property Name:</span>
                  <p>{propertyDetails.property_name}</p>
                </div>
              </div>
              <div className='col-lg-4 col-sm-12 col-6 col-12 col-md-6'>
                <div className='property-address'>
                  <span>Property Address:</span>
                  <p>{propertyDetails.property_address}</p>
                </div>
              </div>
              <div className='col-lg-4 col-sm-12 col-6 col-12 col-md-6'>

              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <h4 className='mb-0 mt-3'>Booking Details</h4>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-4 col-sm-12 col-6 col-12 col-md-6'>
                <div className='booking-date'>
                  <span>Booking Date:</span>
                  {/* <p>{localStorage.getItem('bookingDate')}</p> */}
                  <p>{bookingDate}</p>
                </div>
              </div>
              <div className='col-lg-4 col-sm-12 col-6 col-md-6'>
                <div className='property-address'>
                  <span>Booking Type:</span>
                  <p>{location.state ? location.state.from : ''}</p>
                </div>
              </div>
              <div className='col-lg-4 col-sm-12 col-6 col-12 col-md-6'>
                <div className='property-name'>
                  <span>Booking Time:</span>
                  {/* <p>{localStorage.getItem('timestampTime')}</p> */}
                  <p>

                    {bookingTime}
                  </p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <h4 className='mb-0 mt-3'>Customer Details</h4>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-4 col-sm-12 col-6 col-12 col-md-6'>
                <div className='booking-date'>
                  <span>Customer Name:</span>
                  <p>{localStorage.getItem('custName')}</p>
                </div>
              </div>
              <div className='col-lg-4 col-sm-12 col-6 col-12 col-md-6'>
                <div className='property-name'>
                  <span>Customer Email:</span>
                  <p>{localStorage.getItem('custEmail')}</p>
                </div>
              </div>
              <div className='col-lg-4 col-sm-12 col-12 col-md-6'>
                <div className='property-address'>
                  <span>Customer Phone Number:</span>
                  <p>{localStorage.getItem('custNumber')}</p>
                </div>
              </div>
            </div>
            <div className='row button-row'>
              <div className='col-lg-12'>
                <div className='d-flex justify-content-end mt-3'>
                  <button className="btn my-3 px-4 py-2" onClick={() => navigate(-1)} disabled={boookingConfirmLoading ? true : false}>Cancel</button>
                  <button onClick={() => !bookingId || bookingId === '' ? confirmBooking() : rescheduleBooking()} type="button" className="btn my-3 mx-2 px-4 py-2 d-flex align-items-center" disabled={boookingConfirmLoading ? true : false}>
                   {boookingConfirmLoading ? <ClipLoader size={30} className="me-2 " color='white'/> : null } 
                    Confirm</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> : ''}
    </>
  )
}

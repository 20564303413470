import React, { useContext } from 'react';
// import VideoBooking from './VideoBooking';
import { Button } from 'react-bootstrap';
import PolicyModal from '../Component/PolicyModal';
import RequestRentalModal from '../Component/RequestRentalModal';
import { useState } from 'react';
import { PropertyContext } from '../Context/PropertyContext';
import { useEffect } from 'react';


export default function RecordedVideos() {
  const {propertyDetails} = useContext(PropertyContext)
console.log(propertyDetails, 'propertyDetails');
  const [policyModalShow, setPolicyModalShow] = useState(false);
  const [RentalModalShow, setRentalModalShow] = useState(false);
  // const [recVideos, setRecVideos] = useState([])
  const [recVideoStatus, setRecVideoStatus] = useState([])
  const [recVideoUrl, setVideoUrl] = useState([])
  const [recVideoTitle, setVideoTitle] = useState([])

  useEffect(() => {
    if(propertyDetails.video_viewing_attribute && propertyDetails.video_viewing_attribute !== '') {
      // setRecVideos(JSON.parse(propertyDetails.video_viewing_attribute))
      let parsedString = JSON.parse(propertyDetails.video_viewing_attribute)
      console.log(JSON.parse(parsedString[0].record_video_status), 'abcde');
      if(parsedString[0]){
        setRecVideoStatus(JSON.parse(parsedString[0].record_video_status))
        // console.log(recVideoStatus);
        var parsedUrlArr = JSON.parse(parsedString[0].video_url)
        if(parsedUrlArr.length > 0) {
          var embedArr = parsedUrlArr.map(videoUrl => videoUrl.replace("watch?v=", "embed/"))
          // setVideoUrl(embedArr)
        }
        setVideoUrl(embedArr)
        setVideoTitle(JSON.parse(parsedString[0].video_title))
      }
    } 
  }, [propertyDetails]) // eslint-disable-line react-hooks/exhaustive-deps

  

  // const [videoDetails, setVideoDetails] = useState()

//   useEffect(() => {
//     fetchVideoDetails()
// }, [])
// const fetchVideoDetails = async () => {
//     try {
//       const result = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/property-details`)
//       console.log(result);
//     }
//     catch (error) {
//       console.log(error);
//     }
//   }
  return (
    <>
      <section className='container video-section pb-5 pt-3'>
        <div className='row video-heading-row d-flex'>
          <div className='col-12 virtual-video-heading-col'>
            <h2>Instant Virtual Viewing</h2>
          </div>
          <div className='col-12 modal-btn-col'>
            <Button className='policy-modal-btn mx-2' onClick={() => setPolicyModalShow(true)}>
              Policy
            </Button>

            <PolicyModal
              show={policyModalShow}
              onHide={() => setPolicyModalShow(false)}
            />
            <Button className='rental-modal-btn mx-2' onClick={() => setRentalModalShow(true)}>
              Request Rental
            </Button>

            <RequestRentalModal
             setRentalModalShow={setRentalModalShow}
              show={RentalModalShow}
              onHide={() => setRentalModalShow(false)}
            />
          </div>
          {/* <div className='col-3'>
            
          </div> */}
        </div>


        {/* {propertyDetails.record_video_status === 1 ? (video_viewing_attribute.map(propertyDetails.video_viewing_attribute => { 
          return(
        <div className='row video-row mt-5'>
          <div className='col-12'>
            <h4 className='mb-3'>{propertyDetails.video_title}</h4>
            <iframe className='shadow' src={propertyDetails.video_url} title={propertyDetails.video_title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>)
          })
        </div>): ''} */}

        {
          recVideoStatus.length > 0 ? (recVideoStatus.map((videoStatusItem, index) => { return (videoStatusItem === '0' ? '' :  
          <div className='row video-row mt-5' key={recVideoTitle[index]}>
            <div className='col-12'>
              <h4 className='mb-3'>{recVideoTitle[index]}</h4>
              <iframe className='shadow' src={recVideoUrl[index]} title={recVideoTitle[index]} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
          )})) : ''
        }

        {/* <div className='row video-row mt-5'>
          <div className='col-12'>
            <h4 className='mb-3'>Breathing India</h4>
            <iframe className='shadow' src="https://www.youtube.com/embed/6rcbPwXQUjo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div>
        <div className='row video-row mt-5'>
          <div className='col-12'>
            <h4 className='mb-3'>Incredible India</h4>
            <iframe className='shadow' src="https://www.youtube.com/embed/77czO8sxABI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div>
        <div className='row video-row mt-5'>
          <div className='col-12'>
            <h4 className='mb-3'>INDIA | A short cinematic travel video</h4>
            <iframe className='shadow' src="https://www.youtube.com/embed/S-A1upowdJI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div> */}
        {propertyDetails.navigate_3d_tour_section ? 
        <div className='row video-row mt-5'>
          <div className='col-12'>
            <h4 className='mb-3'>Navigate 3D Tour</h4>
            <iframe title='Navigate 3d tour' src="https://my.matterport.com/show/?m=roWLLMMmPL8" frameBorder="0"></iframe>
          </div>
        </div>
        : ''}
      </section>
    </>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'

export default function PageNotFound() {
    return (
        <>
            <div className='container mt-5 pt-5'>
                <div className='row'>
                    <div className='col-12'>
                        <h2>Property Undefined</h2>
                        {/* <h1 className='text-danger'>Incorrrect URL found</h1>
                        <h3 className='pt-3'>Please enter correct url like /new-property</h3> */}
                        <div>
                        <Link to='/new-property/'>new-property</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

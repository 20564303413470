import React, { useContext, useState } from 'react'
import { PropertyContext } from '../Context/PropertyContext';
import { Container, Col, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment/moment';
import { BsCalendar3 } from "react-icons/bs";

export default function BookingsHistory() {
  // const [propertyDetail, setProppertyDetail] = useState("")
  const navigate = useNavigate();
  const [historyDiv, setHistoryDiv] = useState([]);
  const [show, setShow] = useState(false)
  const [bookingIdState, setBookingIdState] = useState('')
  const [bookingData, setBookingData] = useState('')

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const { propertyDetails, propertySlug } = useContext(PropertyContext)

  useEffect(() => {
    if (propertyDetails && propertyDetails === '') {
      // setProppertyDetail(propertyDetails)
      // console.log(propertyDetail);
    }
  }, [propertyDetails])
  useEffect(() => {
    console.log(historyDiv);
  }, [historyDiv])


  useEffect(() => {
    bookingHistory()
  }, []);

  useEffect(() => {
    console.log(bookingIdState);
  }, [bookingIdState]);

  function handleModalShowHide(bookId) {
    setBookingIdState(bookId)
    setShow(true)
  }

  function bookingHistory() {
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/booking-history`, {
      token: localStorage.getItem("token")
    })
      .then(function (response) {
        console.log(response);
        setBookingData(response.data.status)
        if (response.data.status) {
          // var Dateandtime = new Date(response.data.data[0].booking_date_end_time * 1000)
          var Dateandtime = response.data.data
          var dateAvailabilityData = []
          for (var i = 0; i < Dateandtime.length; i++) {
            var b = new Date(response.data.data[i].booking_date_start_time * 1000)
            var historyTime = moment(b).format('h:mm A');
            console.log(historyTime);
            var a = new Date(b);
            var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            var year = a.getFullYear();
            var month = months[a.getMonth()];
            var date = a.getDate();
            var time = date + ' ' + month + ' ' + year + ',';
            console.log(time);

            dateAvailabilityData.push({
              datehistorytime: historyTime, date: time, startTime: new Date(response.data.data[i].booking_date_end_time * 1000).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
              bookingType: response.data.data[i].booking_type, customerName: response.data.data[i].customer_name,
              propertyName: response.data.data[i].property_name, staffName: response.data.data[i].staff_name,
              bookingId: response.data.data[i].guid, bookingstatus: response.data.data[i].status
            })
          }
          setHistoryDiv(dateAvailabilityData)
          console.log(dateAvailabilityData);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  function Reschedule(bookingId, bookingType) {
    if (bookingType === 0) {
      navigate(`/${propertySlug}/Bookings`, {
        state: {
          bookingId: bookingId,
        }
      })
    }
    else {
      navigate(`/${propertySlug}/videobookings`, {
        state: {
          bookingId: bookingId,
        }
      })
    }

    toast.success(<h6>Please Reschedule Again</h6>)
  }

  function CancelBooking() {
    // console.log("cancel booking");
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/booking-cancel`, {
      booking_id: bookingIdState
    })
      .then(function (response) {
        setShow(false)
        toast.success(<h6>Your booking has been Cancelled</h6>)
        if (response.data.status) {
          bookingHistory()
        }
        console.log(response);

      })
      .catch(function (error) {
        console.log(error);
      })
  }


  return (
    <>
      <h2 className='mt-3 text-center'>Booking History</h2>

      {!bookingData ?
        <>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <h4 className='text-center mt-5 text-danger'>Data Not Found</h4>
              </div>
            </div>
          </div>
        </>
        :
        <>
          <Container className='main_booking mt-5 p-0'>
            <Row className='p-4'>
              {historyDiv && historyDiv.map((historyItem, index) =>
                <Col key={index} lg={12} md={12} className='mb-4 py-4 p-0'>
                  <div className='booking-history-Div shadow-sm p-3'>
                    <div className=''>
                      <div className='d-flex p-1 justify-content-between'><span className='d-flex'>
                        <div className=''><BsCalendar3 />&nbsp; <span className='historydate'>{historyItem.date}</span>
                          <span className='datehistorytime mx-2'>{historyItem.datehistorytime}</span>
                        </div>
                      </span>
                        {historyItem.bookingstatus ?
                          <div className='Activebtn' > Active</div>
                          :
                          <div className='inactivebtn' > Cancelled</div>
                        }
                      </div>
                    </div>
                    <div className='history_property_name'>{historyItem.propertyName}</div>
                    <div className='mt-1 mb-2 history_property_name'>{historyItem.staffName}</div>
                  </div>
                  {historyItem.bookingstatus === 0 ?
                    ""
                    : <div className='bookings-btn'>
                      <div className='text-end p-2 actions_btn'>
                        <button onClick={() => Reschedule(historyItem.bookingId, historyItem.bookingType)} className='btn p-0 mx-1 reschedule-btn text-light'>Reschedule</button>
                        <button onClick={() => handleModalShowHide(historyItem.bookingId)} className='btn p-0 mx-3 text-light Cancel-btn'>Cancel</button>
                      </div>
                    </div>
                  }
                  <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                      <Modal.Title> Cancel booking</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to cancel the booking?</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        No
                      </Button>
                      <Button variant="danger" onClick={() => CancelBooking()}>
                        Yes
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Col>
              )}
            </Row>
          </Container>
        </>
      }
    </>
  )
}

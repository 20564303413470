import React, { useState, useContext } from 'react';
import axios from 'axios';
import { BsFillCalendarCheckFill } from "react-icons/bs";
import { Col, Row } from 'react-bootstrap';
import { PropertyContext } from '../Context/PropertyContext';
import { useNavigate, useLocation } from "react-router-dom";

const MobileCalendarAccordion = ({ date, availibility, weekDay, loggedInStatus, responseData, timestamp }) => {
    const { propertySlug, setTimeStampData } = useContext(PropertyContext)
    const navigate = useNavigate();

    const [isActive, setIsActive] = useState(false);
    // const [selectedDate, setSelectedDate] = useState(new Date());
    // const [showTimeSlot, setShowTimeSlot] = useState(false);
    const [content, setContent] = useState('')
    const location = useLocation();

    function onDayClick(day) {
        console.log(date);
        if (availibility === false) {
            return
        }
        setIsActive(!isActive)
        // setSelectedDate(day);
        // setShowTimeSlot(true);
        localStorage.setItem('bookingDate', day)

        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/virtual-viewing-available-timeslots`, {
            property_id: "uLZ11I",
            selectedDate: day
        })
            .then(function (response) {
                // handle success
                console.log(response);
                // console.log(response.data.data.regulardata);
                const ts = []
                if (response.data.data.available_timeslots.length > 0) {
                    response.data.data.available_timeslots.map((time) => {
                        var s = new Date(time * 1000).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                        console.log(s);
                        ts.push({ time: s, timestamp: time })
                        return 'value'
                    })
                    
                }
                setContent(ts)
                console.log("ts", ts);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })

        // var timestampValue = ''
        // var cc = responseData[0].date
        // console.log(cc);
        // for(var j = 0; j< responseData.length; j++) {
        //   if(responseData[j].date = responseData) {
        //     timestampValue = responseData[j].timestamp
        //   }
        // }
        // console.log(timestampValue);

       

        // localStorage.setItem('timestampTime', time)
        // setTimeStampData(timeslot)
        // if (loggedInStatus === true) {
        //     navigate(`/${propertySlug}/formbuilder`, {
        //         state: {
        //             from: "Video Viewing",
        //             booking_type: 1,
        //             bookingId: location.state ? location.state.bookingId : ''
        //         }
        //     }

        //     )
        // }
        // else {
        //     navigate(`/${propertySlug}/Login`, {
        //         state: {
        //             from: "Video Viewing",
        //             booking_type: 1

        //         }
        //     })
        // }
    }
    function handleShow(timeslot) {
        console.log(timestamp);
        localStorage.setItem('timestamp', timeslot.timestamp)
        localStorage.setItem('timestampTime', timeslot.time)
        localStorage.setItem('bookingType', 1)

        setTimeStampData(timeslot)
        if (loggedInStatus === true) {
            navigate(`/${propertySlug}/formbuilder`, {
                state: {
                    from: "Virtual Viewing",
                    booking_type: 0,
                    bookingId: location.state ? location.state.bookingId : ''
                }
            }

            )
        }
        else {
            navigate(`/${propertySlug}/Login`, {
                state: {
                    from: "Virtual Viewing"
                }
            })
        }
    }
    return (
        <div className="accordion-item">
            <div className="accordion-date" onClick={() => onDayClick(date, availibility)} style={{
                color: availibility ? 'black' : 'lightgrey',
                pointerEvents: availibility ? '' : 'none'

            }}>
                <div className='d-flex justify-content-between'>
                    <div>

                        <BsFillCalendarCheckFill className='me-3' />
                        {weekDay}
                    </div>
                    <div className='d-flex'>

                        <div className=''>{date} <span className='mx-2'></span></div>
                        <div>{isActive ? '-' : '+'}</div>
                    </div>
                </div>
            </div>
            {isActive && <Row style={{
                height: isActive ? 'auto' : '0'
            }} className="accordion-content responsive-calendar-time-slot justify-content-center">{content.length > 0 ? content.map((c, index) =>
                <Col key={index} className='col-4 mb-2' onClick={(e) => handleShow(c)}>{c.time}</Col>
            ) : ''}</Row>}
        </div>
    );
};

export default MobileCalendarAccordion;

import React, {useState, useEffect, useContext} from 'react'
import { PropertyContext } from '../Context/PropertyContext';

export default function AfterConfirm() {
  const {propertyDetails} = useContext(PropertyContext)
  const [bookingDate, setBookingDate] = useState('')
  const [bookingTime, setBookingTime] = useState('')

  function getlength(number) {
    return number.toString().length;
  }

  useEffect(() => {
    // var bookingType = localStorage.getItem('bookingType')
    var getTimeFromDate = new Date(localStorage.timestamp * 1000);
    var getHoursFromDate = getTimeFromDate.getHours()
    var getMinutesFromDate = getTimeFromDate.getMinutes()
    let ampm = getHoursFromDate >= 12 ? '' : 'am'

    let minutesLength = getlength(getMinutesFromDate)
    if (minutesLength === 1) {
      getMinutesFromDate = '0' + getMinutesFromDate
    }
    var dd = String(getTimeFromDate.getDate()).padStart(2, '0');
    var mm = String(getTimeFromDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = getTimeFromDate.getFullYear();
      if (localStorage.getItem('bookingType') === '1') {
        setBookingDate(`${dd}-${mm}-${yyyy}`)
        setBookingTime(`${getHoursFromDate}: ${getMinutesFromDate} ${ampm}`)
      } else if (localStorage.getItem('bookingType') === '0') {
        setBookingDate(localStorage.getItem('bookingDate'))
        setBookingTime(localStorage.getItem('timestampTime'))
      }
  }, [])

  return (
    <>

      <section className='container py-5 booking-main'>
        <div className='row'>
          <h2 className='my-4 text-start'>Booking Confirmed <span className='booking-number'>#1234</span></h2>
          <div className='col-lg-12 mt-3'>
            <div className='row'>
              <div className='col-lg-12'>
                <h4 className='mb-3 '>Property Details</h4>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-4 col-sm-12 col-12 col-6 col-md-6'>
                <div className='property-name'>
                  <span>Property Name:</span>
                  <p>{propertyDetails.property_name}</p>
                </div>
              </div>
              <div className='col-lg-4 col-sm-12 col-6 col-12 col-md-6'>
                <div className='property-address'>
                  <span>Property Address:</span>
                  <p>{propertyDetails.property_address}</p>
                </div>
              </div>
              <div className='col-lg-4 col-sm-12 col-6 col-12 col-md-6'>

              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <h4 className='mb-0 mt-3'>Booking Details</h4>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-4 col-sm-12 col-6 col-12 col-md-6'>
                <div className='booking-date'>
                  <span>Booking Date:</span>
                  <p>{bookingDate}</p>
                </div>
              </div>
              <div className='col-lg-4 col-sm-12 col-6 col-md-6'>
                <div className='property-address'>
                  <span>Booking Type:</span>
                  <p>{localStorage.getItem('bookingType') === '0' ? "Person Booking" : "Video Viewing"}</p>
                </div>
              </div>
              <div className='col-lg-4 col-sm-12 col-6 col-12 col-md-6'>
                <div className='property-name'>
                  <span>Booking Time:</span>
                  <p>{bookingTime}</p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <h4 className='mb-0 mt-3'>Customer Details</h4>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-lg-4 col-sm-12 col-6 col-12 col-md-6'>
                <div className='booking-date'>
                  <span>Customer Name:</span>
                  <p>{localStorage.getItem('custName')}</p>
                </div>
              </div>
              <div className='col-lg-4 col-sm-12 col-6 col-12 col-md-6'>
                <div className='property-name'>
                  <span>Customer Email:</span>
                  <p>{localStorage.getItem('custEmail')}</p>
                </div>
              </div>
              <div className='col-lg-4 col-sm-12 col-12 col-md-6'>
                <div className='property-address'>
                  <span>Customer Phone Number:</span>
                  <p>{localStorage.getItem('custNumber')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}


import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import 'react-calendar/dist/Calendar.css';
// import { Link } from 'react-router-dom';
import Calendar from './Calendar';
export default function PersonBooking({loggedInStatus, setLoggedInStatus}) {
  // const [value, onChange] = useState(new Date());
  // const [timeSection, setTimeSection] = useState(false)
  // const timeSlots = ['10:00', '12:00', '18:00']
  // const handleClickDay = (e) => {
  //   setTimeSection(true)
  // }

  return (
    <>
      <Container>
        <Row className=''>
          <Col >
            <Calendar loggedInStatus={loggedInStatus} setLoggedInStatus={setLoggedInStatus}/>
            {/* <Calendar onClickDay={handleClickDay} onChange={onChange} value={value} /> */}
            

              {/* <div className='time-section'>
                <Link to="login">
                  {timeSlots.map((timeslot) =>
                    <button className='btn timeslot'>{timeslot}</button>
                  )}
                </Link>
              </div> */}

          </Col>

        </Row>

        {/* <Button variant="primary">
          Launch static backdrop modal
        </Button> */}

      </Container>

    </>

  )
}

import React, { useState, useEffect } from 'react'
import Accordion from './Accordion';
import './Accordion.css'


const MobileAccordion = ({ responseData, loggedInStatus }) => {
    const [index, setIndex] = useState(0)
    const [dateData, setDateData] = useState([])
    // const [timeSlots, setTimeSlots] = useState([])
    // const [showTimeSlot, setShowTimeSlot] = useState(false)
    const [disableBtn, setDisableBtn] = useState(false)
    useEffect(() => {
        // console.log(responseData);
        setResponseDataFunc()
    }, [responseData]) // eslint-disable-line react-hooks/exhaustive-deps

    function setResponseDataFunc() {
        if (responseData.length > 0) {
            var dateArray = []
            for (var i = index; i < (index + 6); i++) {
                var a = new Date(responseData[i].date * 1000)
                var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

                var weekDay = days[a.getDay()]
                var dd = String(a.getDate()).padStart(2, '0');
                var mm = String(a.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = a.getFullYear();

                a = mm + '-' + dd + '-' + yyyy;
                dateArray.push({
                    date: a,
                    availibility: responseData[i].is_available,
                    weekDay: weekDay
                })
            }

            setDateData(dateArray)
        }
    }

    function showMoreFun() {
        setIndex(index + 6)
        if (index >= responseData.length) {
            setDisableBtn(true)
        } else {
            setDisableBtn(false)

        }
        setResponseDataFunc()
    }

    useEffect(() => {
        // if (index >= responseData.length) {
        //     setDisableBtn(true)
        // } else {
        //     setDisableBtn(false)

        // }
    }, [index])

    return (
        
        <div className='accordion-main'>
            <h3 className='text-center in-person-viewing-heading my-4'>In-Person Viewing</h3>
            {/* <div className='BookAView'>
                <h2 className='text-start'>Book a viewing</h2>
            </div> */}
            <div className="accord-div pt-3">

                <h3 className=''>Choose one of the available days</h3>
                <div><div className="accordion">
                    {dateData.map(({ date, availibility, weekDay }) => (
                        <Accordion key={date} date={date} availibility={availibility} weekDay={weekDay} loggedInStatus={loggedInStatus} />
                    ))}
                </div>
                    <div className='text-center'>

                        <button disabled={disableBtn ? true : false} onClick={() => showMoreFun()} className='btn showMoreDays'>Show 7 days more</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileAccordion
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useEffect, useState} from 'react'
import axios from 'axios';
import toast from 'react-hot-toast';

function PolicyModal(props) {
  const [refPolicyText, setRefPolicyText] = useState('')
  useEffect(() => {
    fetchPreferenceDetails()
  }, [])

  const fetchPreferenceDetails = async () => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/get-preferences-details`)
      console.log(result);
      let preferenceData = result.data.result
      if(preferenceData){
        if(preferenceData.refund_policy && preferenceData.refund_policy !== '') {
          setRefPolicyText(preferenceData.refund_policy)
        }
      }
        // if(result)
    }
    catch (error) {
      console.log(error);
      toast.error(error.message)
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='policy-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Virtual Viewing Refund Policy
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {refPolicyText}
        </p>
      </Modal.Body>
    </Modal>
  );
}
export default PolicyModal;

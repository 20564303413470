
import './App.css';
import PersonBooking from './Component/PersonBooking';
import RecordedVideos from './Component/RecordedVideos';
import ViewFloorplan from './Component/ViewFloorplan';
import Navigate3Dtour from './Component/Navigate3Dtour';
import Home from './Component/Home';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Login from './Component/Login';
import Header from './Component/Header';
import BookingsPage from './Component/BookingsPage';
import AfterConfirm from './Component/AfterConfirm';
import VideoViewing from './Component/VideoViewing';
import { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import ClipLoader from "react-spinners/ClipLoader";
import axios from 'axios';
import { PropertyContext } from './Context/PropertyContext';
import BookingsHistory from './Component/BookingsHistory';
import PageNotFound from './Component/PageNotFound';
import FormBuilder from './Component/FormBuilder';
import Favicon from "react-favicon";

function App() {
  const [loggedInStatus, setLoggedInStatus] = useState(false);
  const [loading, setloading] = useState(false)
  const [propertyDetails, setPropertyDetails] = useState([])
  const [propertyId, setPropertyId] = useState('')
  const [propertySlug, setPropertySlug] = useState([])
  const [timeStampData, setTimeStampData] = useState({})
  const [custDetails, setCustDetails] = useState({})
  const [headerSrc, setHeaderSrc] = useState('')
  const [logoSrc, setLogoSrc] = useState('')
  const [propertyLogo, setPropertyLogo] = useState('')

  useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoggedInStatus(true)
    }
    fetchPropertyDetails()

    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/get-agency-details`, {

    })
      .then(function (response) {
        console.log(response);
        if (response.data.status === true) {
          setLogoSrc(`${process.env.REACT_APP_API_ENDPOINT}/website_settings/uploads/${response.data.agencyLogo}`)
          setHeaderSrc(`${process.env.REACT_APP_API_ENDPOINT}/website_settings/uploads/${response.data.headerImg}`)
          document.title = (`${response.data.agencyName}`)
        }
      })
      .catch(function (error) {

      })
  }, [])

  const fetchPropertyDetails = async () => {
    let urlArray = window.location.href.split('/').filter(Boolean)
    let slug = urlArray[2]

    setloading(true)
    try {
      const result = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/property-details?property_slug=${slug}`)
      if (result.data.data[0]) {
        setPropertyDetails(result.data.data[0])
        setPropertyId(result.data.data[0].guid)
        setPropertySlug(result.data.data[0].property_slug)
        if (result.data.data[0].property_logo) {

          setPropertyLogo(`${process.env.REACT_APP_API_ENDPOINT}/property/uploads/${result.data.data[0].property_logo}`)
        }
      }
      setloading(false)

    }
    catch (error) {
      console.log(error);
      setloading(false)
      toast.error(error.message)
    }
  }

  function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('custName');
    localStorage.removeItem('custEmail');
    localStorage.removeItem('custNumber');
    localStorage.removeItem('slug');
    localStorage.removeItem('timestamp');
    setLoggedInStatus(false)
    toast.success("Logged out successfully")
  }

  return (
    <>
      <Favicon url={logoSrc}></Favicon>
      {loading ?
        <div>
          <div className='ring-loader'>
            <ClipLoader size={30} className="me-2 " color='black' />
          </div>
        </div>
        : ""}
      <BrowserRouter>
        <PropertyContext.Provider value={{ propertyDetails, fetchPropertyDetails, propertyId, propertySlug, loading, timeStampData, setTimeStampData, custDetails, setCustDetails }}>
          <Header propertyLogo={propertyLogo} headerSrc={headerSrc} logoSrc={logoSrc} loggedInStatus={loggedInStatus} setLoggedInStatus={setLoggedInStatus} logout={logout} />
          <Routes>
            <Route path='/:slug' element={<Home />}></Route>
            <Route path='/:any/bookings' element={<PersonBooking loggedInStatus={loggedInStatus} setLoggedInStatus={setLoggedInStatus} />}></Route>
            <Route path='/:any/login' element={<Login loggedInStatus={loggedInStatus} setLoggedInStatus={setLoggedInStatus} />}></Route>
            <Route path='/:any/videobookings' element={<VideoViewing loggedInStatus={loggedInStatus} setLoggedInStatus={setLoggedInStatus} />}></Route>
            <Route path='/:any/recordedvideos' element={<RecordedVideos />}></Route>
            <Route path='/:any/viewfloorplan' element={<ViewFloorplan />}></Route>
            <Route path='/:any/nagivatetour' element={<Navigate3Dtour />}></Route>
            <Route path='/:any/bookingspage' element={<BookingsPage loggedInStatus={loggedInStatus} setLoggedInStatus={setLoggedInStatus} />}></Route>
            <Route path='/:any/afterconfirm' element={<AfterConfirm />}></Route>
            <Route path='/:any/bookingshistory' element={<BookingsHistory />}></Route>
            <Route path='/:any/formbuilder' element={<FormBuilder />}></Route>
            <Route path='/' element={<PageNotFound />}></Route>
          </Routes>
        </PropertyContext.Provider>

      </BrowserRouter>

      <Toaster />
    </>
  );
}

export default App;

import React, { useContext} from 'react';
import { Container } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';
import { BsFillPinFill } from "react-icons/bs";
// import logo from './images/logo2.jpg';
import { Link } from 'react-router-dom';
import { PropertyContext } from '../Context/PropertyContext';

export default function Header({ loggedInStatus, setLoggedInStatus, logout, logoSrc, headerSrc, propertyLogo }) {
  const { propertyDetails } = useContext(PropertyContext)
  // console.log(propertyDetails);
  // useEffect(() => {
    
  //   // get-agency-details
  
  //   // headerImg
  // }, [])
  return (
    <>
      <section className=''>
        <div style={{backgroundImage: `linear-gradient(180deg, rgba(26, 32, 44, 0) 47.92%, #1A202C 95.31%),url(${headerSrc ? headerSrc : ''})`}} className='main-header'></div>
        <Container>
          <Row>
            <Col className='col-12 col-md d-flex logo-left-col'>
              <div className='logo-prprty-div'></div>
              <div className='logo'>
                <img src={`${propertyLogo ? propertyLogo : (logoSrc ? logoSrc : '')}`} alt="" className='img-fluid' />
              </div>
              <span className='property'>{propertyDetails.property_name}</span>
            </Col>
            <Col className='col-12 col-md text-center text-md-end link-button-col mt-3'>
              <Link to={"/" + propertyDetails.property_slug} className='me-2 btn link-btn1'>
                Home
              </Link>
              {loggedInStatus ?
                <Link to={propertyDetails.property_slug + "/bookingshistory"} className='me-2 btn link-btn2'>
                  Bookings History
                </Link> : ""}
              {loggedInStatus ?
                <div onClick={() => logout()} className='btn link-btn2'>
                  Logout
                </div>
                :
                <Link to={propertyDetails.property_slug + "/login"} className='btn link-btn2'>
                  Login
                </Link>
              }

            </Col>
          </Row>
          <span className='locate-pin'><BsFillPinFill /> {propertyDetails.property_address}</span>
          {/* <span className='header-icon-link1'>
            <a href="#">
              <img src={ZooplaLogo} alt="" />
            </a>
          </span>
          <span className='header-icon-link2'>
            <a href="#">
             <img src={SpareRoom} alt="" />
            </a>
          </span> */}
        </Container>
      </section>
    </>
  )
}

